import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {getTitleFromContent} from 'am-utils';
import DocumentProvider from './providers/DocumentProvider';
import Document from './components/Document';
import HelpBlocksProvider from './providers/HelpBlocksProvider';
import SelectionProvider from './providers/SelectionProvider';
import CollaborativeDocument2 from './components/CollaborativeDocument2';

DocumentContainer.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
    })),
  })).isRequired,
  editModeEditor: PropTypes.shape({}),
  isWizardVisible: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
  responseData: PropTypes.shape({}),
  recalculateWarningsAndPositions: PropTypes.func,
  submitted: PropTypes.bool,
};

function DocumentContainer (props) {
  const {
    content,
    editModeEditor,
    isWizardVisible,
    mode,
    onUpdate,
    readOnly,
    responseData,
    recalculateWarningsAndPositions,
    submitted,
  } = props;

  const title = useMemo(() => getTitleFromContent(content), [content]);
  const {featureFlags} = useWorkspace();
  const useCollaborativeDocument = mode === 'edit' && featureFlags.slateCollaborate;

  return <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <DocumentProvider
      content={content}
      isWizardVisible={isWizardVisible}
      mode={mode}
      readOnly={readOnly}
      responseData={responseData}
      submitted={submitted}>
      <SelectionProvider>
        <HelpBlocksProvider content={content}>
          {useCollaborativeDocument && (
            <CollaborativeDocument2
              content={content}
              onUpdate={onUpdate}
            />
          )}
          {!useCollaborativeDocument && (
            <Document
              content={content}
              editModeEditor={editModeEditor}
              onUpdate={onUpdate}
              recalculateWarningsAndPositions={recalculateWarningsAndPositions}
            />
          )}
        </HelpBlocksProvider>
      </SelectionProvider>
    </DocumentProvider>
  </>;
}

export default DocumentContainer;
