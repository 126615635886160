import {HocuspocusProvider} from '@hocuspocus/provider';
import PropTypes from 'prop-types';
import {withYjs, YjsEditor, withYHistory} from '@slate-yjs/core';
import React, {useEffect, useMemo, useState} from 'react';
import {createEditor, Editor, Transforms} from 'slate';
import {Editable, Slate, withReact} from 'slate-react';
import * as Y from 'yjs';
import FlexSpacer from 'components/FlexSpacer';
import {useItem} from 'providers/ItemProvider';
import Breadcrumbs from 'components/Breadcrumbs';
import tw, {styled, css} from 'twin.macro';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import ContentContainer from 'components/ContentContainer';
import SlateEditor from 'containers/SlateEditor';
import {useDocument} from '../providers/DocumentProvider';
import {useSelection} from '../providers/SelectionProvider';

CollaborativeDocument2.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdate: PropTypes.func,
};

function CollaborativeDocument2 (props) {
  const {
    content,
    onUpdate,
  } = props;

  const {item} = useItem();
  const {urlName: workspaceUrlName} = useWorkspace();
  const documentState = useDocument();

  const {breadcrumbs} = item;
  const showBreadcrumbs = breadcrumbs;

  const {selection, setSelection} = useSelection();
  const memoSelection = useMemo(() => selection, [JSON.stringify(selection)]);

  const {
    selectedElements,
    selectElements,
    selectElementsInRange,
  } = documentState;

  const [value, setValue] = useState([]);

  const provider = useMemo(
    () =>
      new HocuspocusProvider({
        url: window.location.hostname.includes('localhost')
          ? 'ws://a.m.localhost:8080/api/hocus/ws'
          : `wss://${window.location.hostname}/api/hocus/ws`,
        name: 'slate-yjs-demo',
        connect: false,
      }),
    []
  );

  const editor = useMemo(() => {
    const sharedType = provider.document.get('content', Y.XmlText);
    const e = withReact(withYHistory(withYjs(createEditor(), sharedType)));

    // Ensure editor always has at least 1 valid child
    const {normalizeNode} = e;
    // eslint-disable-next-line consistent-return
    e.normalizeNode = (entry) => {
      const [node] = entry;
      if (!Editor.isEditor(node) || node.children.length > 0) {
        return normalizeNode(entry);
      }

      Transforms.insertNodes(
        editor,
        {
          type: 'paragraph',
          children: [{text: ''}],
        },
        {at: [0]}
      );
    };

    return e;
  }, [provider.document]);
  useEffect(() => {
    provider.connect();
    return () => provider.disconnect();
  }, [provider]);
  useEffect(() => {
    YjsEditor.connect(editor);
    return () => YjsEditor.disconnect(editor);
  }, [editor]);

  return (<>
    <ButtonBar>
      <FlexSpacer/>
    </ButtonBar>
    <DocumentWrapper printMode={false}>
      {showBreadcrumbs && (
        <Breadcrumbs
          workspaceUrlName={workspaceUrlName}
          items={breadcrumbs}
          sm
          tw="mb-2"
        />
      )}

      {/* <Slate value={value} onChange={setValue} editor={editor}>
        <Editable />
      </Slate> */}
      <SlateEditor
        content={content}
        editor={editor}
        onChange={onUpdate}
        selectedElements={selectedElements}
        selectElements={selectElements}
        selectElementsInRange={selectElementsInRange}
        selection={memoSelection}
        setSelection={setSelection}

        readOnly={false}
        uploadBase64Image={() => {}}
        recalculateWarningsAndPositions={() => {}}
      />
    </DocumentWrapper>

  </>
  );
}

const ButtonBar = styled.div((props) => [
  tw`
    top-14
    px-4
    flex
    items-center
    justify-end
    sticky
    gap-2
  `,
  css`
    height: 56px;
  `,
]);

const DocumentWrapper = styled(ContentContainer)((props) => [
  // Note: The margin top here is mirrored by a flex spacing div at the bottom
  // of the SlateEditor.
  tw`
    mt-0
  `,
  props.printMode && tw`
    my-8
  `,
]);

export default CollaborativeDocument2;
