import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useItem} from 'providers/ItemProvider';
import Button from 'components/Button';
import FlexSpacer from 'components/FlexSpacer';
import Modal from 'components/Modal';
import A from 'components/A';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import {
  CHECKLIST_TYPE, FINAL_PAGE_TYPE, PAGE_FOR_STEP, getTitleForStep,
} from './wizardConstants';
import useRecommendedTemplatesQuery from './hooks/useRecommendedTemplatesQuery';
import useTemplateContentQuery from './hooks/useTemplateContentQuery';
import ChooseStartScreen from './components/ChooseStartScreen';
import ChooseTypeScreen from './components/ChooseTypeScreen';
import AddTitleScreen from './components/AddTitleScreen';
import UseAIScreen from './components/UseAIScreen';
import TemplatesScreen from './components/TemplatesScreen';

CreatePageWizard.propTypes = {
  onStream: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isStreamLoading: PropTypes.bool.isRequired,
  isStreamError: PropTypes.bool.isRequired,
  insertProcessIfNoStreamingOccured: PropTypes.func.isRequired,
  closeWizard: PropTypes.func.isRequired,
  setAdditionalContext: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
};

function CreatePageWizard (props) {
  const {
    onStream,
    isVisible,
    isStreamLoading,
    isStreamError,
    insertProcessIfNoStreamingOccured,
    closeWizard,
    setAdditionalContext,
    setContent,
    setTitle,
  } = props;

  const {item} = useItem();

  const [step, setStep] = useState(0);
  const [templateRefAndKey, setTemplateRefAndKey] = useState();
  const [finalPageType, setFinalPageType] = useState(FINAL_PAGE_TYPE.TEMPLATES);
  const [checklistType, setChecklistType] = useState();
  const [pageName, setPageName] = useState(item.name);

  const {data: templates, isError: isGetTemplateError, refetch: getTemplates} = useRecommendedTemplatesQuery(`How to ${pageName}`, String(item.id));
  const {data: templateContent, refetch: getTemplateContent} = useTemplateContentQuery(templateRefAndKey?.ref, templateRefAndKey?.key);

  const hasFooter = PAGE_FOR_STEP[step] !== 'CHOOSE_PAGE_TYPE';
  const isTrainingChecklist = checklistType === CHECKLIST_TYPE.TRAINING;
  const isOnboardingChecklist = checklistType === CHECKLIST_TYPE.ONBOARDING;
  const isFormChecklist = checklistType === CHECKLIST_TYPE.FORM;
  const isTaskChecklist = checklistType === CHECKLIST_TYPE.TASK;
  const {featureFlags} = useWorkspace();

  const useCollaborativeDocument = featureFlags.slateCollaborate;

  useEffect(() => {
    if (templateRefAndKey) {
      getTemplateContent();
    }
  }, [templateRefAndKey, getTemplateContent]);

  useEffect(() => {
    if (templateContent) {
      setContent(templateContent);
      closeWizard();
    }
  }, [templateContent, setContent, closeWizard]);

  const closeAndInsertProcess = () => {
    insertProcessIfNoStreamingOccured();
    closeWizard();
  };

  const onCloseModal = () => {
    if (isTrainingChecklist || isOnboardingChecklist || isTaskChecklist) {
      return closeAndInsertProcess();
    }

    return closeWizard();
  };

  const ChoosePageTypeFooter = () => (<>
    <Button
      disabled={!pageName}
      onClick={() => setStep((prev) => prev + 1)}
      primary
      type="button"
    >
    Next
    </Button>
  </>);
  const AddTitleFooter = () => (<>
    <Button
      disabled={!pageName}
      onClick={() => {
        setTitle(pageName);
        setStep((prev) => prev + 1);
      }}
      primary
      type="button"
    >
            Next
    </Button>
  </>);
  const ChooseStartFooter = () => (
    <>
      {!isTaskChecklist && <Button
        type="button"
        gray
        onClick={() => {
          if (isFormChecklist) {
            return closeWizard();
          }

          return closeAndInsertProcess();
        }}
      >
        {isFormChecklist
          ? 'Start with a blank page'
          : 'Start with an empty checklist'}
      </Button>}
    </>
  );
  const UseAIFooter = () => (<>
    <Button type="button" disabled={isStreamLoading} primary onClick={() => {
      onStream();
    }}>
      {isStreamLoading && 'Generating checklist...'}
      {isStreamError && !isStreamLoading && 'Retry'}
      {!isStreamError && !isStreamLoading && 'Generate now'}
    </Button>
  </>);

  const UseTemplatesFooter = () => (<>
    <>
      <A href="/example" target="_blank">
        <Button
          type="button"
          link
        >
        Explore templates
        </Button>
      </A>
      <Button
        type="button"
        gray
        onClick={closeAndInsertProcess}
      >
        Start with an empty checklist
      </Button>
    </>
  </>);

  const renderError = () => (<div>Please check your connection and try again</div>);

  return (
    <Modal
      errorTitle={'Error using AI'}
      footer={hasFooter && (<>
        <Button
          type="button"
          gray
          onClick={() => onBackClick(
            isFormChecklist,
            isOnboardingChecklist,
            isTrainingChecklist,
            setStep
          )}
          disabled={isStreamLoading}>
          Back
        </Button>
        <FlexSpacer/>
        {PAGE_FOR_STEP[step] === 'CHOOSE_PAGE_TYPE' && <ChoosePageTypeFooter/>}
        {PAGE_FOR_STEP[step] === 'ADD_TITLE' && <AddTitleFooter/>}
        {PAGE_FOR_STEP[step] === 'CHOOSE_STARTING_POINT' && <ChooseStartFooter/>}
        {PAGE_FOR_STEP[step] === 'USE_AI_OR_SHOW_TEMPLATES' &&
        finalPageType === FINAL_PAGE_TYPE.AI_GENERATION &&
         <UseAIFooter/>
        }
        {PAGE_FOR_STEP[step] === 'USE_AI_OR_SHOW_TEMPLATES' &&
        finalPageType === FINAL_PAGE_TYPE.TEMPLATES &&
         <UseTemplatesFooter/>
        }
      </>)}
      isBoldTitle
      renderError={isStreamError ? renderError : null}
      onClose={onCloseModal}
      title={getTitleForStep(step, finalPageType)}
      visible={useCollaborativeDocument ? false : isVisible}
    >
      {PAGE_FOR_STEP[step] === 'CHOOSE_PAGE_TYPE' &&
      <ChooseTypeScreen
        setStep={setStep}
        setChecklistType={setChecklistType}
        closeModal={closeWizard}
      />}
      {PAGE_FOR_STEP[step] === 'ADD_TITLE' &&
      <AddTitleScreen
        pageName={pageName}
        setPageName={setPageName}
        setTitle={setTitle}
        setStep={setStep}
      />}
      {PAGE_FOR_STEP[step] === 'CHOOSE_STARTING_POINT' &&
      <ChooseStartScreen
        closeAndInsertProcess={closeAndInsertProcess}
        getTemplates={getTemplates}
        getTemplateContent={getTemplateContent}
        isGetTemplateError={isGetTemplateError}
        isFormChecklist={isFormChecklist}
        isOnboardingChecklist={isOnboardingChecklist}
        isTrainingChecklist={isTrainingChecklist}
        setStep={setStep}
        setTemplateRefAndKey={setTemplateRefAndKey}
        setFinalPageType={setFinalPageType}
        templates={templates}
      />
      }
      {PAGE_FOR_STEP[step] === 'USE_AI_OR_SHOW_TEMPLATES' &&
       finalPageType === FINAL_PAGE_TYPE.AI_GENERATION &&
      <UseAIScreen
        isStreamLoading={isStreamLoading}
        setAdditionalContext={setAdditionalContext}
      />
      }
      {PAGE_FOR_STEP[step] === 'USE_AI_OR_SHOW_TEMPLATES' &&
       finalPageType === FINAL_PAGE_TYPE.TEMPLATES &&
      <TemplatesScreen
        setTemplateRefAndKey={setTemplateRefAndKey}
        templates={templates}
      />
      }
    </Modal>
  );
}

function onBackClick (isOnboardingChecklist, isFormChecklist, isTrainingChecklist, setStep) {
  if (isOnboardingChecklist || isFormChecklist || isTrainingChecklist) {
    return setStep((prev) => prev - 2);
  }

  return setStep((prev) => prev - 1);
}

export default CreatePageWizard;
